﻿import { Action, applyMiddleware, combineReducers, createStore, Reducer } from "redux";
import thunk from "redux-thunk";
import { SessionData } from "./models";
import { alerts } from "./reducers/alerts";
import { CurrentUser, INIT_RUNTIME_STATE, runtime, SET_CONFIG, UPDATE_SESSION_VARIABLES } from "./reducers/runtime";


const middleWareEnhancer = applyMiddleware(thunk);
export const store = createStore(combineReducers({ runtime, alerts }), middleWareEnhancer);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type
export type AppDispatch = typeof store.dispatch
export type TPAction<E> = Action & { payload?: Partial<E> }


// http://stackoverflow.com/questions/32968016/how-to-dynamically-load-reducers-for-code-splitting-in-a-redux-application
function createReducer(asyncReducers) {
    const reducers = { runtime, alerts, ...asyncReducers };

    return combineReducers(reducers);
}

const asyncReducers = {};

export function configureStore(locale: string, currentUser: CurrentUser, sessionData: SessionData, shortDateFormat: string): void {
    store.dispatch({ type: INIT_RUNTIME_STATE });
    store.dispatch({ type: SET_CONFIG, locale, currentUser, shortDateFormat });
    store.dispatch({ type: UPDATE_SESSION_VARIABLES, sessionData });
}

export function injectAsyncReducer(store, name, asyncReducer): void {
    asyncReducers[name] = asyncReducer;
    store.replaceReducer(createReducer(asyncReducers));
}

export function persistedReducer<S>(reducer: Reducer<S>, storageKey: string): Reducer<S> {

    return (state, action): S => {
        if (state === undefined && localStorage.getItem(storageKey)) {
            return JSON.parse(localStorage.getItem(storageKey));
        }

        const newState = reducer(state, action);

        if (newState !== state) {
            const str = JSON.stringify(newState);
            window.localStorage.setItem(storageKey, str);
        }

        return newState;
    };
}
