import { Dialog, styled } from "@material-ui/core";
import { White } from "../../common-design/colors";

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        width: "380px",
        padding: "2em",
        borderRadius: "10px",
        opacity: 0.9,
    },

    "& .MuiInputBase-root": {
        width: "100%",
        marginBottom: "1.4em",
    },

    "& .MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
    },

    "& input:-webkit-autofill-internal-selected": {
        backgroundColor: "none!important",
        "-webkit-box-shadow": `200px 200px 100px ${White} inset`,
        boxShadow: `200px 200px 100px ${White} inset`,
    },

    "& input:-webkit-autofill": {
        "-webkit-box-shadow": `0 0 0px 1000px ${White} inset`,
        zIndex: -1000,
    }


});

export default StyledDialog;