import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

/*
* When <FormattedMessage> with <br /> in it is needed.
* To use: in the .json file add {break} wherever you want the <br />,
* then use this component as you would a normal <FormattedMessage>
*/
export function FormattedMessageBreak(props: FormattedMessage.Props): ReactElement {
    const { values, ...rest } = props;

    return (
        <FormattedMessage
            {...rest}
            values={{
                ...values,
                break: <br />
            }}
        />
    );
}
