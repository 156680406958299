import React, { ReactElement, useRef } from "react";
import { AppBar, Button, Dialog, DialogContent, IconButton, makeStyles, Toolbar, Typography, withStyles, ButtonProps } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useDigSigLogSelector } from "../redux";
import { decrementStep, incrementStep, closeWizard, UPDATE_CONTRACTHTML } from "../actions";
import { marginMd } from "../../../../common-design/margins";
import { Common, CommonElements } from "../../../../common/messages";
import { Gray3, Gray0, TPBlue, TPTurquoiseDark } from "../../../../common-design/colors";
import { StepViewContract } from "./step1/StepViewContract";
import { StepConfirmation } from "./step3/StepConfirmation";
import { StepSigningChoices } from "./step2/StepSigningChoices";

const useStyles = makeStyles({
    rightToolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
});

export const wizardIntl = (str: string): string => `DigitalSigningWizard.${str}`;

export enum WizardSteps {
    ViewContract = 1,
    SigningChoices = 2,
    Confirmation = 3
}

export function DigitalSigningWizard(): ReactElement {
    const { isWizardOpen, wizardStep } = useDigSigLogSelector(state => state.digitalSigningLog);
    const dispatch = useDispatch();
    const classes = useStyles();
    const contractFrame = useRef<HTMLIFrameElement>(null);

    const handleClose = () => dispatch(closeWizard());
    const printReport = () => {
        if (contractFrame.current.contentWindow) {
            contractFrame.current.contentWindow.postMessage({ showSignatures: true }, window.location.origin);
            setTimeout(() => {
                contractFrame.current.contentWindow.print();
                contractFrame.current.contentWindow.postMessage({ showSignatures: false }, window.location.origin);
            });
        }
    };

    return (
        <Dialog
            open={isWizardOpen}
            fullWidth={true}
            maxWidth="md"
            PaperProps={{ style: { height: "100%" } }} // This is the most important height setting!
        >
            <AppBar position="relative">
                <Toolbar className={classes.rightToolbar}>
                    <SignProcessTitle contractFrame={contractFrame} />
                    <span>
                        {wizardStep === WizardSteps.ViewContract &&
                            <Button color="inherit" onClick={printReport} startIcon={<PrintIcon />}>
                                <FormattedMessage {...Common.print} />
                            </Button>
                        }
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: Gray0, overflowY: wizardStep === WizardSteps.ViewContract ? "hidden" : "auto" }}>
                {wizardStep === WizardSteps.ViewContract && <StepViewContract contractFrame={contractFrame} />}
                {wizardStep === WizardSteps.SigningChoices && <StepSigningChoices />}
                {wizardStep === WizardSteps.Confirmation && <StepConfirmation />}
            </DialogContent>
        </Dialog>
    );
}

const StyledBrowseButton = withStyles({
    startIcon: {
        marginRight: 0,
    },
    endIcon: {
        marginLeft: 0
    },
    disabled: {
        "& span": {
            color: Gray3
        }
    },
})(Button);

function BrowseButton(props: ButtonProps) {
    const { style, ...restProps } = props;

    const buttonStyle = {
        backgroundColor: props.disabled ? TPBlue : TPTurquoiseDark,
        padding: "0.3rem",
        borderRadius: "0.25rem",
        ...style
    };

    return <StyledBrowseButton style={buttonStyle} {...restProps}>{props.children}</StyledBrowseButton>;
}

function SignProcessTitle(props) {
    const { wizardStep } = useDigSigLogSelector(state => state.digitalSigningLog);
    const dispatch = useDispatch();

    const handleNextClicked = async () => {
        dispatch(incrementStep());

        if (wizardStep === WizardSteps.ViewContract) {
            const contractHtmlSource = props.contractFrame.current.contentWindow.document.body.parentElement;

            const styles = contractHtmlSource.getElementsByTagName("head")[0].getElementsByTagName("style");
            const stylesHtml = [...styles].map(style => `<style>${style.innerHTML}</style>`).join("");

            const bodyHtml = contractHtmlSource.getElementsByTagName("body")[0].innerHTML;

            contractHtmlSource.innerHTML = `<html><head>${stylesHtml}</head><body>${bodyHtml}</body></html>`;

            dispatch({
                type: UPDATE_CONTRACTHTML,
                payload: { contractHtml: `<!DOCTYPE html>\n${contractHtmlSource.outerHTML}` }
            });
        }
    };

    const wrapperStyle = {
        display: "flex",
        alignItems: "center"
    };

    const isDisabled = (backButton: boolean) => backButton ?
        wizardStep !== WizardSteps.SigningChoices :
        wizardStep !== WizardSteps.ViewContract;

    return (
        <div style={wrapperStyle}>
            <BrowseButton
                style={{ marginRight: marginMd }}
                disabled={isDisabled(true)}
                startIcon={<ChevronLeft />}
                onClick={() => dispatch(decrementStep())}
            >
                {CommonElements.back}
            </BrowseButton>
            <Typography variant="h6">
                <FormattedMessage id={wizardIntl("SigningProcess")} values={{ step: `${wizardStep}` }} />
            </Typography>
            <BrowseButton
                style={{ marginLeft: marginMd }}
                disabled={isDisabled(false)}
                endIcon={<ChevronRight />}
                onClick={handleNextClicked}
            >
                {CommonElements.next}
            </BrowseButton>
        </div>
    );
}
