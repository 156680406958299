
import { defineMessages } from "react-intl";
import { messagify } from "./components/Messagifyer";

export const Business = defineMessages({
    CostDivisions: {
        id: "Business.CostDivisions",
        defaultMessage: "Departments"
    },
    TaskRotation: {
        id: "Business.TaskRotation",
        defaultMessage: "{count, plural, one {Base schedule} other {Base schedules} }",
        values: { count: "1" }
    },
    TaskRotations: {
        id: "Business.TaskRotation",
        defaultMessage: "{count, plural, one {Base schedule} other {Base schedules} }",
        values: { count: "2" }
    },
    StartWeek: {
        id: "Business.StartWeek",
        defaultMessage: "Start week"
    },
    PlannedTime: {
        id: "Business.PlannedTime",
        defaultMessage: "Scheduled work time"
    },
    ReferencePeriodGroup: {
        id: "Business.ReferencePeriodGroup",
        defaultMessage: "{count, plural, one {Reference group} other {Reference groups} }",
        values: { count: "2" }
    },
    ReferencePeriod: {
        id: "Business.ReferencePeriod",
        defaultMessage: "{count, plural, one {Reference period} other {Reference periods} }",
        values: { count: "2" }
    },
    ExpectedWorkTime: {
        id: "Business.ExpectedWorkTime",
        defaultMessage: "Contracted work time"
    },
    Employee: {
        id: "Business.Employee",
        defaultMessage: "Employee"
    }
});

export const Common = {
    add: {
        id: "Common.Add",
        defaultMessage: "Add"
    },
    addExisting: {
        id: "Common.AddExisting",
        defaultMessage: "Add existing"
    },
    addNew: {
        id: "Common.AddNew",
        defaultMessage: "Add new"
    },
    back: {
        id: "Common.Back",
        defaultMessage: "Back"
    },
    next: {
        id: "Common.Next",
        defaultMessage: "Next"
    },
    noResultsFound: {
        id: "Common.NoResultsFound",
        defaultMessage: "No results found",
    },
    cancel: {
        id: "Common.Cancel",
        defaultMessage: "Cancel"
    },
    close: {
        id: "Common.Close",
        defaultMessage: "Close"
    },
    copy: {
        id: "Common.Copy",
        defaultMessage: "Copy"
    },
    create: {
        id: "Common.Create",
        defaultMessage: "Create"
    },
    delete: {
        id: "Common.Delete",
        defaultMessage: "Delete"
    },
    description: {
        id: "Common.Description",
        defaultMessage: "Description"
    },
    enabled: {
        id: "Common.Enabled",
        defaultMessage: "Enabled"
    },
    error: {
        id: "Common.Error",
        defaultMessage: "Error"
    },
    group: {
        id: "Common.Group",
        defaultMessage: "{count, plural, one {Group} other {Groups} }",
        values: { count: "1" }
    },
    groups: {
        id: "Common.Group",
        defaultMessage: "{count, plural, one {Group} other {Groups} }",
        values: { count: "2" }
    },
    loading: {
        id: "Common.Loading",
        defaultMessage: "Loading"
    },
    name: {
        id: "Common.Name",
        defaultMessage: "Name"
    },
    email: {
        id: "Common.Email",
        defaultMessage: "Email"
    },
    no: {
        id: "Common.No",
        defaultMessage: "No"
    },
    new: {
        id: "Common.New",
        defaultMessage: "New"
    },
    save: {
        id: "Common.Save",
        defaultMessage: "Save"
    },
    print: {
        id: "Common.Print",
        defaultMessage: "Print"
    },
    send: {
        id: "Common.Send",
        defaultMessage: "Send"
    },
    confirmDeletion: {
        id: "Common.ConfirmDeletion",
        defaultMessage: "Are you sure you want to delete?"
    },
    confirmNavigateOnEdit: {
        id: "Common.ConfirmNavigateOnEdit",
        defaultMessage: "You have made changes that has not been saved. Are you sure that you want to leave the page without saving?"
    },
    result: {
        id: "Common.Result",
        defaultMessage: "Result"
    },
    total: {
        id: "Common.Total",
        defaultMessage: "Total"
    },
    type: {
        id: "Common.Type",
        defaultMessage: "Type"
    },
    yes: {
        id: "Common.Yes",
        defaultMessage: "Yes"
    },
    update: {
        id: "Common.Update",
        defaultMessage: "Update"
    },
    edit: {
        id: "Common.Edit",
        defaultMessage: "Edit"
    },
    finishEditing: {
        id: "Common.FinishEditing",
        defaultMessage: "Finish editing"
    },
    upload: {
        id: "Common.Upload",
        defaultMessage: "Upload"
    },
    restore: {
        id: "Common.Restore",
        defaultMessage: "Restore"
    },
    saved: {
        id: "Common.Saved",
        defaultMessage: "Saved"
    },
    ok: {
        id: "Common.Ok",
        defaultMessage: "OK"
    }
};

export const CommonShort = defineMessages({
    from: {
        id: "CommonShort.From",
        defaultMessage: "From"
    },
    until: {
        id: "CommonShort.Until",
        defaultMessage: "Until"
    },
    week: {
        id: "CommonShort.Week",
        defaultMessage: "W"
    }
});

export const Time = defineMessages({
    date: {
        id: "Time.Date",
        defaultMessage: "Date"
    },
    dateInterval: {
        id: "Time.DateInterval",
        defaultMessage: "Date interval"
    },
    days: {
        id: "Time.Days",
        defaultMessage: "Days"
    },
    weekCount: {
        id: "Time.WeekCount",
        defaultMessage: "{week, number} {week, plural, one {week} other {weeks} }"
    },
});

export const WeekDays = defineMessages({
    monday: {
        id: "WeekDays.Monday",
        defaultMessage: "Monday"
    },
    tuesday: {
        id: "WeekDays.Tuesday",
        defaultMessage: "Tuesday"
    },
    wednesday: {
        id: "WeekDays.Wednesday",
        defaultMessage: "Wednesday"
    },
    thursday: {
        id: "WeekDays.Thursday",
        defaultMessage: "Thursday"
    },
    friday: {
        id: "WeekDays.Friday",
        defaultMessage: "Friday"
    },
    saturday: {
        id: "WeekDays.Saturday",
        defaultMessage: "Saturday"
    },
    sunday: {
        id: "WeekDays.Sunday",
        defaultMessage: "Sunday"
    },
});

export const PlaceHolders = defineMessages({
    enterName: {
        id: "Placeholders.EnterName",
        defaultMessage: "Enter name",
    },
    selectPerson: {
        id: "Placeholders.SelectPerson",
        defaultMessage: "Select person..."
    },
    selectCostDivision: {
        id: "Placeholders.SelectCostDivision",
        defaultMessage: "Select department..."
    }
});

export const Hosting = defineMessages({
    UnsupportedWebBrowser: {
        id: "Hosting.UnsupportedWebBrowser",
        defaultMessage: "This part of the application requires an up-to-date web browser. Google Chrome, Edge or Firefox is recommended"
    }
});

export const ContractRules = defineMessages({
    Rank: {
        id: "ContractRules.Rank",
        defaultMessage: "Rank"
    },
    FixedVacationDays: {
        id: "ContractRules.FixedVacationDays",
        defaultMessage: "Amount of vacation days:"
    },
    EmploymentType: {
        id: "ContractRules.EmploymentType",
        defaultMessage: "Employment type"
    },
    VacationCalcType: {
        id: "ContractRules.VacationCalcType",
        defaultMessage: "Type of vacation calc."
    },
    ContractRules: {
        id: "ContractRules.ContractRules",
        defaultMessage: "Agreements - Contract rules"
    },
    save: {
        id: "ContractRules.Save",
        defaultMessage: "Save"
    },
    leaveIncorrectContractRule: {
        id: "ContractRules.LeaveIncorrectContractRule",
        defaultMessage: "The current contract rule is incomplete. To continue, please select at least one rule on rank, employment type and type of vacation calculation"
    },
    AvailableAsTemporaryContract: {
        id: "ContractRules.AvailableAsTemporaryContract",
        defaultMessage: "Available as temporary contract"
    }
});

export const ImportSalarySpec = defineMessages({
    ImportSalarySpec: {
        id: "ImportSalarySpec.ImportSalarySpec",
        defaultMessage: "Import salary specification"
    },
    ImportSalarySpecTitle: {
        id: "ImportSalarySpec.ImportSalarySpecTitle",
        defaultMessage: "Salary - Import salary specification"
    }
});

export const ReferencePeriods = defineMessages({
    ErrorMessage: {
        id: "ReferencePeriods.ErrorMessage",
        defaultMessage: "A reference group name should be provided. Valid dates for each reference period must be provided and cannot overlap."
    }
});

export const Relatives = defineMessages({
    Children: {
        id: "Relatives.Children",
        defaultMessage: "Children"
    },
    Relative: {
        id: "Relatives.Relative",
        defaultMessage: "Next of kin"
    },
    Name: {
        id: "Relatives.Name",
        defaultMessage: "Name"
    },
    Birthday: {
        id: "Relatives.Birthday",
        defaultMessage: "Birthdate"
    },
    Telephonenumber1: {
        id: "Relatives.Telephonenumber1",
        defaultMessage: "Phone 1"
    },
    Telephonenumber2: {
        id: "Relatives.Telephonenumber2",
        defaultMessage: "Phone 2"
    },
    Address: {
        id: "Relatives.Address",
        defaultMessage: "Address"
    },
    City: {
        id: "Relatives.City",
        defaultMessage: "City"
    },
    ZipCode: {
        id: "Relatives.ZipCode",
        defaultMessage: "Postcode"
    },
    Country: {
        id: "Relatives.Country",
        defaultMessage: "Country"
    },
    WarningMessage: {
        id: "Relatives.WarningMessage",
        defaultMessage: "Both name and birthdate should be filled in for each child. Birthdate should be in the format 'YYYY-MM-DD'. Character limit of 50 for all fields except phone number at 100."
    },
    HasConnections: {
        id: "Relatives.HasConnections",
        defaultMessage: "This child is connected to other fields in Timeplan and can't be deleted."
    }
});

export const BusinessElements = messagify(Business);
export const CommonElements = messagify(Common);
export const CommonShortElements = messagify(CommonShort);
export const TimeElements = messagify(Time);
export const WeekDayElements = messagify(WeekDays);
export const ContractRulesElements = messagify(ContractRules);
export const ImportSalarySpecElements = messagify(ImportSalarySpec);
export const ReferencePeriodElements = messagify(ReferencePeriods);
export const RelativesElements = messagify(Relatives);
