import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { Gray0, Gray1, TPBlue, TPGreen, TPRed, TPTurquoise, TPYellow } from "../common-design/colors";

export interface IStyleClasses {
    leftIcon: string;
    shortSwitch: string;
}

export const styles = theme => ({
    leftIcon: {
        marginRight: theme.spacing(),
        overflow: "visible"
    },
    shortSwitch: {
        height: 24
    }
});

export const theme = createMuiTheme({
    overrides: {
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottomColor: TPYellow,
                }
            }
        },
        MuiToolbar: {
            dense: {
                height: 40,
                minHeight: 40
            }
        }
    },
    palette: {
        primary: {
            dark: TPTurquoise,
            main: TPBlue
        },
        secondary: {
            main: TPGreen
        },
        error: {
            main: TPRed
        },
        action: {
            disabled: Gray1,
            disabledBackground: Gray0
        }
    },
    typography: {
        allVariants: {
            fontFamily: "Lato",
            textTransform: "none"
        },
    }
});


export const styleApplier = withStyles(styles);
