import React, { ReactElement } from "react";
import { injectIntl, IReactIntlProps } from "../extensions";
import { Common } from "../messages";

interface ConfirmNavigateOnEditProps extends IReactIntlProps {
    warn: boolean;
}

@injectIntl
export class ConfirmNavigateOnEdit extends React.Component<ConfirmNavigateOnEditProps> {

    componentDidMount(): void {
        const w: any = window;
        w.IsFormNotChanged = this.isFormNotChanged;
    }

    isFormNotChanged = (hasSubMenu: boolean): number | boolean => {
        const { intl, warn } = this.props;

        if (!warn || hasSubMenu) {
            return 1;
        } else {
            return confirm(intl.formatMessage(Common.confirmNavigateOnEdit));
        }
    }

    render(): ReactElement {
        return null;
    }
}
