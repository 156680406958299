import { TypedUseSelectorHook, useSelector } from "react-redux";
import { LoadState } from "../../../common/models";
import { RootState } from "../../../common/store";
import { SigningMethod } from "./api";
import { LogRow, PersonInfo, Pricing } from "./DigitalSigningLog";
import { SigningStatus } from "./SigningStatusBox";

export type DigitalSigningLogState = {
    loadState: LoadState;
    // Gemensam
    contractRef: number;
    isWizardOpen: boolean;
    // Logs
    logs: LogRow[];
    signingStatus: SigningStatus;
    readonly: boolean;
    // Wizard
    wizardStep: number;
    // Step 1
    contractHtml: string;
    // Step 2 / Form
    employeeInfo: PersonInfo;
    employerInfo: PersonInfo;
    pdfAttachment: File;
    signingMethod: SigningMethod;
    pricing: Pricing;
}

export type DigitalSigningLogCombinedState = RootState & { digitalSigningLog: DigitalSigningLogState };

export const useDigSigLogSelector: TypedUseSelectorHook<DigitalSigningLogCombinedState> = useSelector;