import { REQUEST_ERROR } from "../../common/HttpClient";

export interface IError {
    text: string;
    json: any;
    message: any;
}

export const ERROR_SEEN = "ERROR_SEEN";

export function alerts(state: IError[], action): IError[] {

    if (state === undefined) {
        return [];
    }

    if (action.type === ERROR_SEEN) {
        return state.slice(0, state.length - 1);
    } else if (action.message === REQUEST_ERROR) {
        return state.concat([{ json: action.jsonError, text: action.textError, message: action.userMessage }]);
    }

    return state;
}
