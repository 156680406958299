import mapValues from "lodash/mapValues";
import * as moment from "moment";
import * as React from "react";

import { FormattedMessage } from "react-intl";

const isIso8601 = (value) => moment(value.substr(0, 10), "YYYY-MM-DD", true).isValid();

function formatDateValues(values) {
    return mapValues(values, (value) => {
        if (typeof value === "string" && isIso8601(value)) {
            return Date.parse(value) ? new Date(value) : value;
        }
        return value;
    });
}

export const IntlMessage = ({ id, values }: { id: string; values: any }) => {
    return <FormattedMessage
        id={id}
        defaultMessage="Message not translated"
        values={formatDateValues(values) } />;
};
