import * as React from "react";

export interface IHoverableState {
    hovered?: boolean;
}

export class Hoverable<TProps, TState extends IHoverableState> extends React.PureComponent<TProps, TState> {
    hoverOn = () => this.setState({ hovered: true } as any);
    hoverOff = () => this.setState({ hovered: false } as any);
}
