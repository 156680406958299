import React, { CSSProperties, ReactElement } from "react";
import { Cancel, CheckCircleRounded, InfoRounded } from "@material-ui/icons";
import { borderRadius } from "../../../common-design/borders";
import { lighterShade, TPGreen, TPRed, TPRed_Light, TPTurquoise } from "../../../common-design/colors";
import { marginLg, marginMd } from "../../../common-design/margins";
interface MessageBoxProps {
    type: "done" | "info" | "warning" | "error";
    children: ReactElement;
    cancelAlignment?: boolean;
    style?: CSSProperties;
}

const typeToProps = [
    { type: "done", color: TPGreen, icon: <CheckCircleRounded /> },
    { type: "info", color: TPTurquoise, icon: <InfoRounded /> },
    { type: "error", color: TPRed, lightColor: TPRed_Light, icon: <Cancel /> }
];

export function MessageBox(props: MessageBoxProps): ReactElement {
    const { color, lightColor, icon } = typeToProps.find(e => e.type === props.type);

    const style: CSSProperties = {
        display: "flex",
        alignItems: !props.cancelAlignment && "center",
        justifyContent: "flex-start",
        marginBottom: marginLg,
        background: lightColor ?? lighterShade(color, 100),
        border: `1px solid ${color}`,
        borderRadius: borderRadius,
        padding: marginMd,
        ...props.style
    };

    return (
        <div style={style}>
            {icon && <span style={{ color, marginRight: marginMd }}>{icon}</span>}
            {props.children}
        </div>
    );
}
