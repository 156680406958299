import moment from "moment";
import { fn } from "moment";
import { DateTime } from "luxon";
import { AppDispatch } from "./store";

// we would like an ISO8601 string that reflects the moment's utcOffset()
// src: https://momentjs.com/docs/#/displaying/as-json/
fn.toJSON = function() { return this.format(); };

export interface IRequestHandler {
    method: string;
    url: string;
    headers?: { [index: string]: string };
    formattedPayload: any;
    payload;
    allowCache: boolean;
}

const simpleRequest = (method, url, allowCache = true): IRequestHandler => ({
    allowCache,
    formattedPayload: undefined,
    headers: {},
    method,
    payload: undefined,
    url
});

const jsonRequest = (method, url, payload): IRequestHandler => ({
    allowCache: false,
    formattedPayload: JSON.stringify(payload),
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    method,
    payload,
    url
});

const formDataRequest = (method, url, payload: FormData): IRequestHandler => ({
    allowCache: false,
    formattedPayload: payload,
    method,
    payload,
    url
});

export class HttpRequest {
    static delete = (url) => simpleRequest("DELETE", url);

    static get = (url, allowCache = true) => simpleRequest("GET", url, allowCache);

    static post = (url) => simpleRequest("POST", url, false);

    static patch = (url, patch) => jsonRequest("PATCH", url, patch);

    static postJson = (url, payload) => jsonRequest("POST", url, payload);

    static postFormData = (url: string, formData: FormData): IRequestHandler => formDataRequest("POST", url, formData);

    static putJson = (url, payload) => jsonRequest("PUT", url, payload);
}

export const momentReviver = function(key, value) {
    if (typeof value === "string") {
        // avoid invalid parsing of simple numbers
        if (value.length > 12) {
            const m = moment(value, moment.ISO_8601, true);
            if (m.isValid()) {
                return m;
            }
        }
    }
    return value;
};

export const momentReviverUtc = function(key, value) {
    if (typeof value === "string") {
        const m = moment.utc(value, moment.ISO_8601, true);
        if (m.isValid()) {
            return m;
        }
    }
    return value;
};

export const luxonReviver = (key, value) => {
    let a;
    if (typeof value === "string") {
        // avoid invalid parsing of simple numbers
        if (value.length > 12) {
            const dateTime = DateTime.fromISO(value);
            if (dateTime.isValid) {
                return dateTime;
            }
        }
    }
    return value;
};

const toJsonRegular = (response) => response.json();

const toJsonMoments = (response) => {
    return response.text().then((text) => {
        return JSON.parse(text, momentReviver);
    });
};

const toJsonLuxon = (response) => {
    return response.text().then((text) => {
        return JSON.parse(text, luxonReviver);
    });
};

export enum DateHandling {
    None,
    Moment,
    Luxon
}

export class HttpClient {
    apiHostUrl: string;

    jsonParser: (response) => any;

    constructor(apiHostUrl, dateHandling: DateHandling = DateHandling.None) {
        this.apiHostUrl = apiHostUrl;

        this.jsonParser =
            dateHandling === DateHandling.Moment ? toJsonMoments :
                dateHandling === DateHandling.Luxon ? toJsonLuxon :
                    toJsonRegular;
    }

    responseToJson = (response: Response) => {
        const contentType = response.headers.get("content-type");

        if (response.status >= 200 && response.status < 300) {
            if (!contentType || contentType.indexOf("text/plain") >= 0) {
                return response.text();
            } else if (contentType.indexOf("application/json") === -1) {
                return response.text().then((message) => Promise.reject({ message, response }));
            } else {
                return this.jsonParser(response);
            }
        } else {
            if (!contentType || contentType.indexOf("text/plain") >= 0) {
                return response.text().then((message) => Promise.reject({ message, response }));
            } else {
                return response.json().then((jsonError) => Promise.reject({ jsonError, response }));
            }
        }
    };
    
    fetch(request: IRequestHandler, signal?: AbortSignal) {
        const credentials: RequestCredentials = "include";

        const args = {
            credentials,
            method: request.method,
            headers: request.headers,
            body: request.formattedPayload,
            signal: signal || null
        };

        return fetch(this.apiHostUrl + request.url, args)
            .then(this.responseToJson);
    }
}

// higher level types
export const REQUEST_SEND = "REQUEST_SEND";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_ERROR = "REQUEST_ERROR";

export class HttpDispatch {
    fetch: (request: IRequestHandler) => Promise<any>;

    dispatch: AppDispatch;

    constructor(httpClient: HttpClient, dispatch) {
        this.fetch = (r) => httpClient.fetch(r);
        this.dispatch = dispatch;
    }

    dispatchHttp = (type, handler, internal = null) => {
        const baseArgs = { type, internal, request: handler.payload, url: handler.url };
        const dispatch = (args) => this.dispatch({ ...args, ...baseArgs });

        dispatch({ message: REQUEST_SEND });

        return this.fetch(handler)
            .then((response) => {
                dispatch({ message: REQUEST_SUCCESS, response });
                return response;
            })
            .catch((error) => {
                if (error.jsonError) {
                    dispatch({ message: REQUEST_ERROR, jsonError: error.jsonError });
                } else {
                    dispatch({ message: REQUEST_ERROR, textError: error.message });
                }

                throw error;
            });
    };
}
