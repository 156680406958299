import { LoadState } from "../../../../common/models";
import { TPAction } from "../../../../common/store";
import { INIT, LOAD_LOGS, SET_LOAD_STATE, UPDATE_SIGNINGMETHOD, OPEN_WIZARD, UPDATE_EMPLINFO, UPDATE_PDFATTACHMENT, UPDATE_STEP, UPDATE_CONTRACTHTML, CLOSE_WIZARD } from "../actions";
import { SigningMethod } from "../api";
import { DigitalSigningLogState } from "../redux";
import { SigningStatus } from "../SigningStatusBox";

const initialState: DigitalSigningLogState = {
    loadState: LoadState.Normal,
    logs: [],
    signingStatus: SigningStatus.New,
    readonly: true,
    contractRef: 0,
    isWizardOpen: false,
    employeeInfo: { name: "", email: "" },
    employerInfo: { name: "", email: "" },
    wizardStep: 1,
    contractHtml: null,
    pdfAttachment: null,
    signingMethod: SigningMethod.ElectronicID,
    pricing: {
        electronicId: { price: 19.5 },
        checkbox: { price: 0 }
    }
};

export default function signingLogsReducer(
    state: DigitalSigningLogState = initialState,
    action: TPAction<DigitalSigningLogState>
): DigitalSigningLogState {
    const { type, payload } = action;

    switch (type) {
        case INIT: {
            return {
                ...state,
                contractRef: payload.contractRef,
                employeeInfo: payload.employeeInfo,
                employerInfo: payload.employerInfo,
                pricing: payload.pricing
            };
        }
        case SET_LOAD_STATE: {
            return { ...state, loadState: payload.loadState };
        }
        case LOAD_LOGS: {
            return {
                ...state,
                loadState: LoadState.Idle,
                logs: payload.logs,
                signingStatus: payload.signingStatus,
                readonly: payload.readonly
            };
        }
        case OPEN_WIZARD: {
            return { ...state, isWizardOpen: true };
        }
        case CLOSE_WIZARD: {
            return { ...state, isWizardOpen: false, wizardStep: 1 };
        }
        case UPDATE_EMPLINFO: {
            return {
                ...state,
                ...payload
            };
        }
        case UPDATE_STEP: {
            return {
                ...state,
                wizardStep: payload.wizardStep
            };
        }
        case UPDATE_PDFATTACHMENT: {
            return {
                ...state,
                pdfAttachment: payload.pdfAttachment
            };
        }
        case UPDATE_SIGNINGMETHOD: {
            return {
                ...state,
                signingMethod: payload.signingMethod
            };
        }
        case UPDATE_CONTRACTHTML: {
            return {
                ...state,
                contractHtml: payload.contractHtml
            };
        }
        default:
            return state;
    }
}