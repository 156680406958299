import * as api from "./api";
import { LoadState } from "../../../common/models";
import { AppDispatch } from "../../../common/store";
import { DigitalSigningLogCombinedState } from "./redux";
import { Action } from "redux";

export const INIT = "INIT";
export const LOAD_LOGS = "LOAD_LOGS";
export const SET_LOAD_STATE = "SET_LOAD_STATE";
export const OPEN_WIZARD = "OPEN_WIZARD";
export const CLOSE_WIZARD = "CLOSE_WIZARD";
export const UPDATE_EMPLINFO = "UPDATE_EMPLINFO";
export const UPDATE_STEP = "UPDATE_STEP";
export const UPDATE_PDFATTACHMENT = "UPDATE_PDFATTACHMENT";
export const UPDATE_SIGNINGMETHOD = "UPDATE_SIGNINGMETHOD";
export const UPDATE_CONTRACTHTML = "UPDATE_CONTRACTHTML";

export function incrementStep(): (dispatch: AppDispatch, getState: () => DigitalSigningLogCombinedState) => void {
    return updateStep(1);
}

export function decrementStep(): (dispatch: AppDispatch, getState: () => DigitalSigningLogCombinedState) => void {
    return updateStep(-1);
}

function updateStep(step: number) {
    return (dispatch: AppDispatch, getState: () => DigitalSigningLogCombinedState): void => {
        dispatch({ type: UPDATE_STEP, payload: { wizardStep: getState().digitalSigningLog.wizardStep + step } });
    };
}

export function loadSigningLogs() {
    return async (dispatch: AppDispatch, getState: () => DigitalSigningLogCombinedState): Promise<void> => {
        const { digitalSigningLog } = getState();

        if (digitalSigningLog.contractRef === 0) {
            console.warn("No contractRef given to log");
            return null;
        }

        loadingHelper(
            dispatch,
            () => api.getSigningLogs(digitalSigningLog.contractRef),
            LOAD_LOGS);
    };
}

export function openWizard(): Action {
    return { type: OPEN_WIZARD };
}

export function closeWizard() {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: CLOSE_WIZARD });
        dispatch(loadSigningLogs());
    };
}

async function loadingHelper(dispatch, body, loadedType): Promise<void> {
    dispatch({ type: SET_LOAD_STATE, payload: { loadState: LoadState.Normal } });

    let slow = false;
    let complete = false;
    setTimeout(() => {
        if (!complete) {
            slow = true;
            dispatch({ type: SET_LOAD_STATE, payload: { loadState: LoadState.Slow } });
        }
    }, 150);

    const payload = await body();
    complete = true;

    const loadedDone = () => {
        dispatch({ type: loadedType, payload });
    };

    if (slow) {
        setTimeout(() => {
            loadedDone();
        }, 150);
    } else {
        loadedDone();
    }
}