import { Typography } from "@material-ui/core";
import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { marginXl } from "../../../../../common-design/margins";
import { ConfirmButton } from "../../../../../common/components/buttons/ConfirmButton";
import { FormattedMessageBreak } from "../../../../../common/components/formattedMessage/FormattedMessageBreak";
import { LoadingAndDone } from "../../../../../common/components/loading/LoadingAndDone";
import { Common } from "../../../../../common/messages";
import { closeWizard } from "../../actions";
import { startContractSigning } from "../../api";
import { useDigSigLogSelector } from "../../redux";
import { wizardIntl } from "../DigitalSigningWizard";

export function StepConfirmation(): ReactElement {
    const {
        contractRef,
        employeeInfo,
        employerInfo,
        contractHtml,
        pdfAttachment,
        signingMethod
    } = useDigSigLogSelector(state => state.digitalSigningLog);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function sendContractForSigning() {
            const contractData = {
                signingMethod,
                employee: employeeInfo,
                employer: employerInfo,
                contractHtml,
                attachments: pdfAttachment && [pdfAttachment]
            };

            try {
                await startContractSigning(contractRef, contractData);
                setDone(true);
            } catch(e) {
                setError(true);
            }
        }

        sendContractForSigning();
    }, []);

    const centerDiv = {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties;

    const centerContent = {
        width: "28rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    } as CSSProperties;

    const confirmButtonStyle = {
        visibility: done ? "visible" : "hidden",
        marginTop: marginXl,
    } as CSSProperties;
    

    const DoneMessage = () => <FormattedMessageBreak id={wizardIntl("SendDone")} />;
    const ErrorMesage = () => <FormattedMessageBreak id={wizardIntl("SendError")} />;
    const ProcessingMessage = () => (
        <>
            <FormattedMessage id={wizardIntl("SendProcessing")} />
            <br />
            <p style={{ visibility: "hidden" }}>filler</p>{/* To ensure the height always stays the same */}
        </>
    );

    return (
        <div style={centerDiv}>
            <div style={centerContent}>
                <LoadingAndDone done={done} error={error} />
                <Typography style={{ textAlign: "center" }} variant="subtitle1">
                    {done ? <DoneMessage /> : error ? <ErrorMesage /> : <ProcessingMessage />}
                </Typography>
                <ConfirmButton style={confirmButtonStyle} onClick={() => dispatch(closeWizard())}>
                    <FormattedMessage {...Common.close} />
                </ConfirmButton>
            </div>
        </div>
    );
}