import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { CheckCircleRounded } from "@material-ui/icons/index";
import { FormattedMessage } from "react-intl";
import { lighterShade, TPRed, White } from "../../../common-design/colors";
import { withStyles } from "@material-ui/styles";

export const OkButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            color="primary"
            variant="contained"
            {...props}>
            OK
        </Button>
    );
};

export const CompletedButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            color="primary"
            variant="contained"
            startIcon={<CheckCircleRounded />}
            {...props}>
            <FormattedMessage id={"Common.Completed"} />
        </Button>
    );
};

export const CancelButton = (props: ButtonProps): React.ReactElement => {
    return (
        <Button
            variant="outlined"
            color="primary"
            {...props}>
            <FormattedMessage id={"Common.Cancel"} />
        </Button>
    );
};


const ButtonDeleteStyle = withStyles({
    root: {
        color: White,
        backgroundColor: TPRed,
        "&:hover": {
            backgroundColor: lighterShade(TPRed, 90)
        }
    }
})(Button);

export const DeleteButton = (props: ButtonProps): React.ReactElement => {
    return (
        <ButtonDeleteStyle
            variant="contained"
            startIcon={<DeleteIcon />}
            {...props}
        >
            <FormattedMessage id={"Common.Delete"} />
        </ButtonDeleteStyle>
    );
};
