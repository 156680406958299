import { DateHandling, HttpClient, HttpRequest } from "../../../common/HttpClient";
import { LogRow, PersonInfo } from "./DigitalSigningLog";
import { SigningStatus } from "./SigningStatusBox";

let client: HttpClient = null;

export const initialize = (url: string): void => {
    client = new HttpClient(url + "/DigitalSigning/", DateHandling.Luxon);
};

export const getSigningLogs = (contractRef: number): Promise<{ rows: LogRow[], signingStatus: SigningStatus, readonly: boolean }> => {
    return client.fetch(HttpRequest.get(`${contractRef}/logs`));
};

export enum SigningMethod {
    ElectronicID = 0,
    Checkbox = 1
}

export type SignContractData = {
    signingMethod: SigningMethod;
    employee: PersonInfo;
    employer: PersonInfo;
    contractHtml: string;
    attachments: File[];
}

async function convertToBase64(file: File) {
    return new Promise<string>(resolve => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = event.target.result as string;
            resolve(data.substr(data.indexOf("base64,") + "base64,".length));
        };

        reader.readAsDataURL(file);
    });
}

export const startContractSigning = async(contractRef: number, signContractData: SignContractData): Promise<void> => {
    const { contractHtml, attachments } = signContractData;

    const toDocumentModel = async (file: File) => {
        return {
            fileName: file.name,
            contentType: file.type,
            base64Content: await convertToBase64(file)
        };
    };

    const newSignContractData = {
        ...signContractData,
        contractHtml,
        attachments: attachments && await Promise.all(attachments.map(toDocumentModel))
    };

    return client.fetch(HttpRequest.postJson(`${contractRef}/send`, newSignContractData));
};

export const cancelContractSigning = (contractRef: number): Promise<void> => {
    return client.fetch(HttpRequest.post(`${contractRef}/cancel`));
};
