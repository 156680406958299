import * as React from "react";
import { addLocaleData, IntlProvider } from "react-intl";

import * as localeEn from "react-intl/locale-data/en";
import * as localeSv from "react-intl/locale-data/sv";
import * as localeDa from "react-intl/locale-data/da";
import * as localeFi from "react-intl/locale-data/fi";
import * as localeNb from "react-intl/locale-data/nb";

import * as moment from "moment";
import "moment/locale/sv";
import { Settings } from "luxon";

import { flattenMessages } from "../extensions";

interface LocalizationProviderProps {
    locale: string;
    localizedMessages: object;
    root?: boolean;
    children?: any;
}

export function LocalizationProvider (props: LocalizationProviderProps){
    const { localizedMessages, root, children, locale } = props;

    if (root) {
        addLocaleData([...localeSv, ...localeEn, ...localeDa, ...localeFi, ...localeNb]);

        moment.locale(locale);

        moment.updateLocale(locale, {
            longDateFormat: {
                LT: "HH:mm",
                LTS: "HH:mm:ss",
                L: "YYYY-MM-DD",
                LL: "D MMMM YYYY",
                LLL: "D MMMM YYYY [kl.] HH:mm",
                LLLL: "dddd D MMMM YYYY [kl.] HH:mm",
                lll: "D MMM YYYY HH:mm",
                llll: "ddd D MMM YYYY HH:mm"
            },
            week: {
                dow: 1, // monday is the first day of the week,
                doy: 4
            }
        });

        Settings.defaultLocale = locale;
    }

    const messages = flattenMessages(localizedMessages[locale]);

    // force 24h clock in all locales
    const defaultFormat = {
        time: {
            short: {
                hour: "numeric",
                minute: "numeric",
                hour12: false
            }
        }
    };

    // force iso8601 dates 2017-07-24 in all locales
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
    const reactIntlLocale = locale + "-ca-iso8601";

    return (
        <IntlProvider
            defaultLocale="en-ca-iso8601"
            locale={reactIntlLocale}
            messages={messages}
            defaultFormats={defaultFormat}
        >
            {children}
        </IntlProvider>
    );
}
