import React, { MutableRefObject, ReactElement } from "react";
import { useDigSigLogSelector } from "../../redux";

export function StepViewContract(props: { contractFrame: MutableRefObject<HTMLIFrameElement> }): ReactElement {
    const { apiUrl } = useDigSigLogSelector(state => state.runtime);
    const { contractRef } = useDigSigLogSelector(state => state.digitalSigningLog);

    const reportSrc = `${apiUrl}/report?contractRef=${contractRef}&showSignatures=false`;

    return (
        <div style={{ height: "100%" }}>
            <iframe
                ref={props.contractFrame}
                src={reportSrc}
                width="100%"
                height="100%"
                frameBorder="0"
            />
        </div>
    );
}
