import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { MessageBox } from "../../../common/components/messageBox/MessageBox";
import { LoadState } from "../../../common/models";
import { useDigSigLogSelector } from "./redux";

export enum SigningStatus {
    New,
    Sent,
    PartiallySigned,
    Signed,
    Rejected
}

export function SigningStatusBox(): ReactElement {
    const { signingStatus, loadState } = useDigSigLogSelector(state => state.digitalSigningLog);

    const statusToTypeAndTitle = {
        [SigningStatus.New]: { type: "info" as const, titleId: "DigitalSigningLog.NewContract", numSigned: undefined },
        [SigningStatus.Sent]: { type: "info" as const, titleId: "DigitalSigningLog.SentContract", numSigned: "0" },
        [SigningStatus.PartiallySigned]: { type: "info" as const, titleId: "DigitalSigningLog.SentContract", numSigned: "1" },
        [SigningStatus.Signed]: { type: "done" as const, titleId: "DigitalSigningLog.SignedByAllParties", numSigned: undefined },
        [SigningStatus.Rejected]: { type: "error" as const, titleId: "DigitalSigningLog.RejectedContract", numSigned: undefined }
    };
    const { type, titleId, numSigned } = statusToTypeAndTitle[signingStatus];

    const titleStyle = {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    return (
        <MessageBox
            type={type}
            style={loadState === LoadState.Idle ? {} : { visibility: "hidden" as const }}
        >
            <div style={{ width: "100%" }}>
                <div  style={titleStyle}>
                    <FormattedMessage id={titleId} values={{ numSigned: numSigned }} />
                </div>
            </div>
        </MessageBox>
    );
}
