import React, { useRef } from "react";
import { Dialog, DialogContent, Toolbar, Button, IconButton, AppBar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";
import * as runtime from "../../../common/reducers/runtime";
import PrintIcon from "@material-ui/icons/Print";
import { makeStyles } from "@material-ui/core/styles";
import * as margins from "../../../common-design/margins";
import { Common } from "../../../common/messages";

export interface PrintContractPopUpProps {
    open: boolean;
    handleClose: () => void;
    contractRef: string;
    classes: null;
}

const useStyles = makeStyles({
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
    }
});

export function PrintContractPopUp(props: PrintContractPopUpProps): React.ReactElement {
    const { open, contractRef } = props;
    const classes = useStyles();
    const contractFrame = useRef<HTMLIFrameElement>(null);
    const handleClose = (): void => {
        props.handleClose();
    };

    function printReport() {
        if (contractFrame.current.contentWindow) {
            contractFrame.current.contentWindow.print();
        }
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <div style={{ overflow: "hidden" }}>
                <AppBar position="relative">
                    <Toolbar className={classes.rightToolbar}>
                        <Button autoFocus color="inherit" onClick={printReport} startIcon={<PrintIcon />}>
                            {<FormattedMessage {...Common.print} />}
                        </Button>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div id="divcontents" style={{ marginBottom: margins.marginLg, overflow: "visible" }}>
                        <iframe
                            ref={contractFrame}
                            src={`${runtime.getUrls().apiUrl}/report?contractRef=${contractRef}&showSignatures=true`}
                            width="100%"
                            height="550px"
                            frameBorder="0"
                        />
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
}