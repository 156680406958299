import * as React from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { FormattedMessage, InjectedIntl } from "react-intl";
import { injectIntl } from "../../common/extensions";
import { OkButton, CancelButton } from "../../common/components/buttons/ActionButtons";

interface Callbacks {
    showConfirmDialog: () => void;
}

interface OnDemandModalProps {
    title?: FormattedMessage.Props;
    message: FormattedMessage.Props;
    handler: () => void;
    children?: (callbacks: Callbacks) => React.ReactElement;
    intl?: InjectedIntl;
}

interface OnDemandModalState {
    visible: boolean;
}

/*
    Supports different kinds of modals:
        Confirm [OK, Cancel]
        Alert [OK] (TODO)
        Retry [Retry, Cancel] (TODO)
        ...
*/
@injectIntl
export class OnDemandModal extends React.Component<OnDemandModalProps, OnDemandModalState> {

    constructor(props: OnDemandModalProps) {
        super(props);
        this.state = {
            visible: false
        };
    }

    initConfirm(): void {
        this.setState({ visible: true });
    }

    confirmed = (): void => {
        this.setState({ visible: false });
        this.props.handler();
    }

    cancel = (): void => this.setState({ visible: false });

    render(): React.ReactElement {
        const { title, message, children } = this.props;

        const actions = [
            <OkButton key="ok" onClick={this.confirmed} />,
            <CancelButton key="cancel" onClick={this.cancel} />
        ];

        const clientArgs = {
            showConfirmDialog: this.initConfirm.bind(this)
        };

        return (
            <div style={{ display: "inline-block" }}>
                <Dialog open={this.state.visible}>
                    {title &&
                        <DialogTitle>
                            <FormattedMessage {...title} />
                        </DialogTitle>
                    }
                    <DialogContent>
                        <FormattedMessage {...message} />
                    </DialogContent>
                    <DialogActions>{actions}</DialogActions>
                </Dialog>
                {children(clientArgs)}
            </div>
        );
    }
}
