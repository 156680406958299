export function validatePersonName(value: string): boolean {
    // Intentionally very permissive (https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/)
    return !!value.trim();
}

export function validateEmail(value: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
    return re.test(String(value).toLowerCase());
}

function isNumberOrDecimal(elem: string): boolean {
    if (elem.match(/^[0-9]+$/) || elem.match(/^\d+(\.\d{1,2})?$/)) {
        return true;
    }
    return false;
}

function isOperator(elem: string): boolean {
    if (elem.match(/^[+|\-|*|/]{1}$/)) {
        return true;
    }
    return false;
}

// If a constant contains an operator ex: [Anst h/v]
function findConstant(elem: string, i: number, elements: string[]): [string, number[]] {
    // Take all next elements until one ends with ]
    let elemStr = elem;
    let nextElement = elements[i+1];
    const removeIndex = [];
    while(nextElement && !nextElement.endsWith("]")) {
        elemStr += nextElement;
        nextElement = elements[++i+1];
        removeIndex.push(i);
    }
    removeIndex.push(++i);

    return [elemStr += nextElement, removeIndex];
}

export function validateFormulaWhileEditing(formula: string, constants: string []): boolean {
    const delFormula = formula.split(/([-+*/])/).filter(n => n);
    let removeIndex = [];
    let paranthesesCounter = 0;
    const formulaElems = delFormula.map((elem, i, elements) => {
        if (removeIndex.includes(i)) {
            return "REMOVE";
        }
        // Parantheses validation
        if (elem.includes("(")) {
            elem = elem.replace(/\(/, "");
            paranthesesCounter++;
        } else if (elem.includes(")")) {
            elem = elem.replace(/\)/, "");
            paranthesesCounter--;
        }

        if (isNumberOrDecimal(elem) || isOperator(elem)) {
            return true;
        }
        // Constants validation
        if (elem.startsWith("[")) {
            if(elem.endsWith("]")) {
                return constants.includes(elem);
            } else {
                let tmpElem = "";
                [tmpElem, removeIndex] = findConstant(elem, i, elements);
                return constants.includes(tmpElem);
            }
        }
    }).filter(e => e != "REMOVE");

    return (
        formulaElems.every(e => e) && 
        paranthesesCounter === 0
    );
}

export function createScriptFromFormula(formula: string, constants: string[]): string {
    let strFormula = formula;

    for (let i = 0; i < constants.length; i++) {
        const varIndex = i + 1;
        const varName = `a${varIndex}`;
        strFormula = `var ${varName} = ${varIndex}; \n${strFormula}`;
        strFormula = strFormula.replaceAll(constants[i], varName);
    }

    return strFormula;
}

export function validateFormulaAtSave(formula: string, constants: string[]) {
    try {
        return eval(createScriptFromFormula(formula, constants));
    }
    catch(ex) {
        console.debug("Could not compute formula: ", formula);
        console.log("Exception: ", ex);
        return false;
    }
}

// Translate to DB format according to rules from server
export function translateFormula(
    formula: string,
    ruleConstants: { rule: string; value: string } []
): string {
    const delFormula = formula.split(/([-+*/()])/).filter(n => n);
    let removeIndex = [];
    const translatedFormula = delFormula
        .map((elem, i, elements) => {
            if (removeIndex.includes(i)) {
                return "REMOVE";
            }

            if (elem.startsWith("[")) {
                if(!elem.endsWith("]")) {
                    let tmpElem = "";
                    [tmpElem, removeIndex] = findConstant(elem, i, elements);
                    elem = tmpElem;
                }
            }
            const ruleElem = ruleConstants.find(x => x.value === elem);
            if (ruleElem) {
                return ruleElem.rule;
            }
            if (isNumberOrDecimal(elem)) {
                return `[VA:${elem}]`;
            }
            return elem;
        })
        .filter(e => e != "REMOVE")
        .join("");

    return translatedFormula;
}
declare global {
    interface String {
        replaceAll(searchString: string, replaceString: string): string;
    }
}

String.prototype.replaceAll = function(searchString, replaceString): string {
    let temp = this;
    let index = temp.indexOf(searchString);
    while (index != -1) {
        temp = temp.replace(searchString, replaceString);
        index = temp.indexOf(searchString);
    }
    return temp;
};

export {};
