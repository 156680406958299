import * as React from "react";
import * as ReactDOM from "react-dom";
import { MuiThemeProvider } from "@material-ui/core";
import { LocalizationProvider } from "../common/components";
import { theme } from "../common-forms/styles";
import { FormulaPopUp, FormulaPopUpProps } from "./components/CostCalculation/FormulaPopUp";
import { PrintContractPopUpProps, PrintContractPopUp } from "./components/StaffContract/PrintContractPopUp";
import { DigitalSigningLog, DigitalSigningLogProps } from "./components/StaffContract/DigitalSigningLog";
import { configureStore, injectAsyncReducer, store } from "../common/store";
import { Provider } from "react-redux";
import signingLogsReducer from "./components/StaffContract/reducers/signingLogsReducer";
import { ResetPassword, ResetPasswordProps } from "./components/ResetPassword/ResetPassword";

interface ReactWebFormsProps {
    locale: string;
    elementId: string;
}

export function renderComponent<E>(ComponentToRender: React.FunctionComponent<E>, props: E, reactWebFormsProps: ReactWebFormsProps): void {
    const { locale, elementId } = reactWebFormsProps;

    const localizedMessages = {
        sv: { ...require("../common/intl/sv.json"), ...require("./utils/intl/sv.json") },
        en: { ...require("../common/intl/en.json"), ...require("./utils/intl/en.json") }
    };

    const root = true;
    const rootComponent = (
        <Provider store={store}>
            <LocalizationProvider {...{locale, localizedMessages, root}}>
                <MuiThemeProvider theme={theme}>
                    <ComponentToRender {...props} />
                </MuiThemeProvider>
            </LocalizationProvider>
        </Provider>
    );

    ReactDOM.render(
        rootComponent,
        document.getElementById(elementId)
    );
}

export const renderFormulaPopUp = (props: FormulaPopUpProps & { locale: string }) : void => {
    const { locale, ...restProps} = props;
    renderComponent<FormulaPopUpProps>(
        FormulaPopUp,
        restProps,
        {
            locale,
            elementId: "popUpRoot"
        }
    );
};

export const renderPrintContractPopUp = (props: PrintContractPopUpProps & { locale: string }) : void => {
    const { locale, ...restProps} = props;
    renderComponent<PrintContractPopUpProps>(PrintContractPopUp, restProps, { locale, elementId: "popUpRoot" });
};

export const renderDigitalSigningLog = (props: DigitalSigningLogProps & { locale: string }): void => {
    const { locale, ...restProps} = props;

    configureStore(locale, null, {}, "yyyy-MM-dd");
    injectAsyncReducer(store, "digitalSigningLog", signingLogsReducer);

    renderComponent<DigitalSigningLogProps>(
        DigitalSigningLog,
        restProps,
        {
            locale,
            elementId: "rightPanelContainer"
        }
    );
};

export const renderResetPassword = (props: ResetPasswordProps & { locale: string }) : void => {
    const { locale, ...restProps } = props;
    renderComponent<ResetPasswordProps>(ResetPassword, restProps, { locale, elementId: "popupRoot" });
};
