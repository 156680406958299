import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField, InputAdornment } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";
import { translateFormula, validateFormulaAtSave, validateFormulaWhileEditing } from "../../utils/validation";

export interface FormulaPopUpProps {
    open: boolean;
    handleClose: () => void;
    handleSave: (formula: string, dbFormula: string) => void;
    constants: string[];
    ruleConstants: { value: string; rule: string }[];
    formula: string;
}

export function FormulaPopUp(props: FormulaPopUpProps): React.ReactElement {
    const { open, ruleConstants } = props;
    const [formula, setFormula] = useState({
        value: props.formula,
        isValid: true
    });
    useEffect(() => {
        setFormula({
            value: props.formula,
            isValid: true
        });
    }, [props.formula]);
    const constants = [
        ...props.constants,
        ...ruleConstants.map(e => e.value)
    ];
    const formulaInput = useRef(null); // To focus input after pressing a button
    
    const handleClose = (): void => {
        setFormula({
            value: props.formula,
            isValid: true
        });
        props.handleClose();
    };
    const handleSave = (): void => {
        if (validateFormulaAtSave(formula.value, constants)) {
            props.handleSave(formula.value, translateFormula(formula.value, ruleConstants));
            handleClose();
        } else {
            setFormula({ ...formula, isValid: false });
        }
    };

    const actions = [
        <SaveButton
            key="save"
            disabled={!formula.isValid}
            onClick={(): void => handleSave()}
        />,
        <CancelButton key="cancel" onClick={(): void => handleClose()} />
    ];

    const FormulaPopUpTitle = ({ children }): React.ReactElement => {
        return (
            <DialogTitle>
                {children}
                <IconButton style={{ padding: 0, float: "right" }} onClick={(): void => handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
        );
    };

    const handleChange = (e): void => {
        setFormula({
            value: e.target.value,
            isValid: validateFormulaWhileEditing(e.target.value, constants)
        });
    };
    const variableButtonOnClick = (text: string): void => {
        const newValue = formula.value + text;
        setFormula({
            value: newValue,
            isValid: validateFormulaWhileEditing(newValue, constants)
        });
        formulaInput.current.focus();
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="sm"
        >
            <FormulaPopUpTitle>
                <FormattedMessage id="PopUp.FormulaTitle" />
            </FormulaPopUpTitle>
            <DialogContent>
                <TextField
                    style={{ minWidth: "100%" }}
                    value={formula.value}
                    multiline
                    onChange={handleChange}
                    label={<FormattedMessage id="PopUp.FormulaTextField" />}
                    variant="outlined"
                    error={!formula.isValid}
                    inputRef={formulaInput}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={(): void => setFormula({ value: "", isValid: true })}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                    }}
                />
                <p style={{ marginTop: "20px", marginBottom: "16px" }}>
                    <FormattedMessage id="PopUp.Constants" />
                </p>
                {constants.map(text => (
                    <ConstantButton key={text} onClick={(): void => variableButtonOnClick(text)} text={text} />
                ))}
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
}

const SaveButton = (buttonProps): React.ReactElement => {
    return (
        <Button
            key="ok"
            color="primary"
            variant="contained"
            {...buttonProps}>
            <FormattedMessage id={"Common.Save"} />
        </Button>
    );
};

const CancelButton = ({ onClick }): React.ReactElement => {
    return (
        <Button
            key="cancel"
            color="primary"
            onClick={onClick}>
            <FormattedMessage id={"Common.Cancel"} />
        </Button>
    );
};

const ConstantButton = ({ onClick, text }): React.ReactElement => {
    return (
        <Button
            key={text}
            color="primary"
            variant="contained"
            onClick={onClick}
            style={{ marginRight: "16px", marginBottom: "16px" }}
        >
            <p>{text}</p>
        </Button>
    );
};
